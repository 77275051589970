import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/publishReplay';
import { HttpClient } from '@angular/common/http';
import { HttpService } from './httpService';
import { User } from '../model/user';
import { RestAPI } from '../api.constants';
import { UrlUtils } from '../utils/url-utils';

@Injectable()
export class UserService extends HttpService {

    localUser: Observable<User>;
    private userUpdateStatus: BehaviorSubject<User>;
    localUserState: Observable<User>;

    constructor(http: HttpClient) {
        super(http);
        this.userUpdateStatus = new BehaviorSubject<User>(null);
        this.localUserState = this.userUpdateStatus.asObservable();
    }

    getLoggedInUser(): Observable<User> {
        // get users from api

        if (!this.localUser) {
            this.getLocalUserFromServer();
        }

        return this.localUser;
    }

    // Use this whenever user information is upadated
    // like update to favorites, purchased a content, upgraded to premium, updated profile

    reloadUserInfo() {
        this.getLocalUserFromServer()
            .subscribe((user: User) => {
                if (user) {
                    this.userUpdateStatus.next(user);
                }

            });
    }

    keepAlive(token: String) {
        return this.get(RestAPI.KEEP_ALIVE + token + '/');
    }


    private getLocalUserFromServer() {
        this.localUser = this.get<User>(RestAPI.GET_LOGEDDIN_USER)
            .publishReplay(1)
            .refCount();

        return this.localUser;
    }

    getUserByResetPaswordToken(token: string) {
        return this.get(RestAPI.GET_USER_BY_RESETPSWD_TOKEN + "/" + token);
    }

    getUser(id: string) {
        return this.get(RestAPI.GET_USER + '/' + id);
    }

    getUserByAccessToken(token: string) {
        return this.get(UrlUtils.formatUrl(RestAPI.GET_USER_BY_TOKEN, token));
    }

    updateUser(name: string, email: string, mobileNo: string, userId: string) {
        return this.put(RestAPI.PUT_USER + '/' + userId, { name: name, email: email, mobileNo: mobileNo });
    }

    changePassword(user: User) {
        return this.post(RestAPI.CHANGE_PASSWORD, user);
    }

    sendOTP(mobileNo: string, isResendOTP) {
        return this.post(RestAPI.SEND_OTP + '/' + mobileNo + '/' + isResendOTP, null);
    }

    verifyOTP(mobileNo: string, otp: string) {
        return this.put(RestAPI.VERIFY_OTP + '/' + mobileNo + '/' + otp + '/true', null);
    }

    validateEmailorMobile(email: string, password: string) {
        return this.post(RestAPI.VERIFY_EMAIL_MOBILE, { email: email, password: password });
    }

    resetPasswordUsingMobileNo(user: any, otp: string) {
        return this.put(RestAPI.RESET_PASSWORD_MOBILE + '/' + otp, user);
    }

    //Admin Users
    addAdminUsers(user: User) {
        return this.post(RestAPI.POST_ADMIN_USER, user);
    }

    updateAdminUsers(user: User) {
        return this.put(RestAPI.PUT_ADMIN_USER + '/' + user.id, user);
    }

    getAllUsers() {
        return this.get(RestAPI.GET_ALL_ADMIN_USER);
    }

    getAllRoles() {
        return this.get(RestAPI.GET_ALL_ROLES);
    }

    getSignupUsers() {
        return this.get(RestAPI.GET_ALL_SIGNUP_USER);
    }

    //Notifications
    sendEmail(formData: FormData) {
        return this.post(RestAPI.SEND_EMAIL, formData);
    }

    uploadImage(formData: FormData) {
        return this.post(RestAPI.POST_IMAGE, formData);
    }

    sendSMS(formData: FormData) {
        return this.post(RestAPI.SEND_SMS, formData);
    }
}
