import { Component, OnInit } from '@angular/core';
import { RouteInfo } from "./sidebar.metadata";
import { UserService } from '../service/user.service';
import { UserRolesService } from '../user/user-roles.service';
import { UserRole } from '../user/user-role.model';
import { User } from '../model/user';

declare var $: any;
@Component({
    // moduleId: module.id,
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {

    userRoles: Array<string>;
    menu: RouteInfo[] = [];

    constructor(private userService: UserService, private userRolesService: UserRolesService) {
    }

    ngOnInit() {
        $.getScript('./assets/js/app-sidebar.js');
        this.init();
    }

    private getAll() {
        const MENUITEMS: RouteInfo[] = [

            {
                path: '/admin', title: 'Dashboard', icon: 'ft-grid', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
            },
            {
                path: '/admin/home-banner', title: 'Home Banner', icon: 'ft-image', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
            },
            {
                path: '/admin/auditorium', title: 'Auditorium', icon: 'ft-cpu', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
            },
            {
                path: '/admin/auditorium-floor', title: 'Auditorium Floors', icon: 'ft-align-center', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
            },
            {
                path: '/admin/auditorium-rows', title: 'Auditorium Rows', icon: 'ft-grid', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
            },
            {
                path: '/admin/event', title: 'Event', icon: 'ft-video', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
            },
            {
                path: '/admin/ticket-block', title: 'Block Ticket', icon: 'ft-layers', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
            },
            {
                path: '/admin/discount', title: 'Discount', icon: 'fa fa-rupee', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
            },
            {
                path: '', title: 'Notifications', icon: 'ft-bell', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill float-right mr-1 mt-1', isExternalLink: false,
                submenu: [
                    { path: '/admin/notifications/send-email', title: 'Email', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                    { path: '/admin/notifications/send-sms', title: 'SMS', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
                ]
            },
            {
                path: '', title: 'User Management', icon: 'ft-users', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill float-right mr-1 mt-1', isExternalLink: false,
                submenu: [
                    { path: '/admin/admin-users', title: 'Admin Users', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                ]
            },
            {
                path: '', title: 'Reports', icon: 'ft-file-text', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill float-right mr-1 mt-1', isExternalLink: false,
                submenu: [
                    { path: '/admin/reports/signed-up-users', title: 'Signed Up Users', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                    { path: '/admin/reports/event-booking-report', title: 'Event Booking Report', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                    { path: '/admin/reports/daily-sales-report', title: 'Daily Sales Report', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                    { path: '/admin/reports/daily-sales-report-by-category', title: 'Daily Sales Report By Level', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                    { path: '/admin/reports/daily-sales-report-by-transaction', title: 'Sales Transaction Report', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                    { path: '/admin/reports/daily-sales-report-by-transaction-cancelled', title: 'Cancelled Transaction Report', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                    { path: '/admin/reports/daily-sales-report-by-user', title: 'Sales Report - User', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                ]
            }, 
            {
                path: '', title: 'Settings', icon: 'ft-settings', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill float-right mr-1 mt-1', isExternalLink: false,
                submenu: [
                    { path: '/admin/settings/email', title: 'Email', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
                ]
            }           
        ];
        return MENUITEMS;
    }

    private init(): void {
        this.userService.getLoggedInUser()
            .subscribe((user: User) => {
                this.userRoles = user.authorities;
                this.createMenu();
            });
    }

    private createMenu() {
        // Reset
        this.menu = [];
        // Get all avialable states
        const validStates: Array<string> = [];
        this.userRolesService.getUserRoles().forEach((userRole: UserRole) => {
            if (this.userRoles && this.userRoles.includes(userRole.role)) {
                validStates.push(...userRole.states);
            }
        });

        // Iterate and create response menu
        const MENUITEMS = this.getAll();
        MENUITEMS.forEach((value: RouteInfo) => {
            const lvalue: RouteInfo = Object.assign({}, value);
            lvalue.submenu = [];
            if (value.submenu && value.submenu.length > 0) {
                value.submenu.forEach((child: RouteInfo) => {
                    if (validStates.includes(child.path)) {
                        lvalue.submenu.push(child);
                    }
                });
                if (lvalue.submenu && lvalue.submenu.length > 0) {
                    this.menu.push(lvalue);
                }
            }
            else {
                if (validStates.includes(lvalue.path)) {
                    this.menu.push(lvalue);
                }
            }
        });

        return this.menu;
    }
}
