import { NavigationEnd, Router } from '@angular/router';
import { ServiceStatusService } from 'app/shared/common/service/service-status.service';
import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { filter } from 'rxjs/operators';
declare var window: any;

@Component({
    selector: 'app-root',
    template: `<ngx-loading-bar></ngx-loading-bar><router-outlet></router-outlet>`,
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {

    isPending: boolean;

    constructor(private router: Router,
        private serviceStatus: ServiceStatusService,
        private cdr: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.serviceStatus.serviceState.subscribe(state => {
            if (this.isPending !== state) {
                this.isPending = state;
                this.cdr.detectChanges();
            }
        });

        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            window.scroll(0, 0);
        });
    }

    ngOnDestroy() {
        this.serviceStatus.unSubscribe();
    }
}
