import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { UserRole } from '../user/user-role.model';
import { UserRolesService } from '../user/user-roles.service';
import { JwtToken } from '../model/jwtToken';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router,
    private userRolesService: UserRolesService,
    private authService: AuthService) {

  }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user: JwtToken = this.authService.getUser();

    if (user && this.checkRoles(user.roles, state.url)) {
      return true;
    } else {
      this.router.navigate(['/session/home']);
      return false;
    }
  }

  private checkRoles(roles: string[], url: string): boolean {
    for (const role of roles) {
      var url_string = url;
      const userRole: UserRole = this.userRolesService.getUserRole(role);
      if (userRole) {
        for (const state of userRole.states) {
          // Find last parameter of url and if its not matched to role states then it will be removed
          // Ex : admin/user-management/edit-user-role/5acda8cb6ee25c11183d262c
          // 1) /5acda8cb6ee25c11183d262c  --> this parameter is not matched, so its removed
          // 2) admin/user-management/edit-user-role --> after removed parameter from url
          var subStr = url_string.substring(url_string.lastIndexOf("/"), url_string.length);
          if (userRole.states.toString().indexOf(subStr) === -1) {
            url_string = url_string.replace(subStr, '');
          } else {
            // Check url and role state is equal
            if (state === url_string) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }
}
