export class RestAPI {
  static LOGIN = "/api/user/login";
  // static LOGOUT = '/api/session/user/logout';
  static POST_SIGNUP_USER = 'api/user/create';
  static GET_LOGEDDIN_USER = "api/session/user";
  static POST_RESEND_EMAIL = "/api/user/email/activation";
  static GET_VALIDATE_EMAIL = "/api/user/validate/email/";
  static POST_FORGOT_PASSWORD = "/api/user/forgot/password";
  static GET_USER_BY_RESETPSWD_TOKEN = "api/user/reset/password";
  static GET_USER = "api/admin/session/user";
  static GET_USER_BY_TOKEN = "api/user/{0}";
  static PUT_USER = "api/user/update";
  static CHANGE_PASSWORD = "api/session/user/change/password";
  static GET_USER_LOCATION = "api/user/location";
  static SEND_OTP = "api/user/send_otp";
  static VERIFY_OTP = 'api/user/validate/mobile';
  static VERIFY_EMAIL_MOBILE = 'api/user/verify_email_mobile';
  static RESET_PASSWORD_MOBILE = 'api/user/reset/password';
  static KEEP_ALIVE = "";

  // Admin Users
  static POST_ADMIN_USER = "api/admin/session/admin_users/create";
  static GET_ALL_ADMIN_USER = "api/admin/session/admin_users";
  static PUT_ADMIN_USER = "api/admin/session/admin_users/update";
  static GET_ALL_SIGNUP_USER = "api/admin/session/signup_users";

  //Get all roles
  static GET_ALL_ROLES = "/api/admin/session/all-roles";

  //State & City
  static GET_STATE = "api/location/getStateList";
  static GET_CITY = "api/location/getCityList";

  //Stadium
  static GET_ALL_STADIUM_TYPES = "api/stadiumTypeList";
  
  //Dashboard
  static GET_DASHBOARD_EVENTS = 'api/admin/session/dashboard/event';
  static GET_ALL_BOOKING = 'api/admin/session/booking';
  
  //Auditorium
  static POST_AUDITORIUM = "api/admin/session/auditorium/create";
  static PUT_AUDITORIUM = "api/admin/session/auditorium/update";
  static GET_AUDITORIUM = "api/session/auditorium";
  static GET_ALL_AUDITORIUM = "api/auditoriumList";
  static PUT_AUDITORIUM_SEAT_LAYOUT_IMAGE = "api/admin/session/auditorium/seat-layout/update";

  //Auditorium Floors
  static POST_FLOOR = "api/admin/session/floor/create";
  static PUT_FLOOR = "api/admin/session/floor/update";
  static GET_FLOOR = "api/session/floor";
  static GET_ALL_FLOOR = "api/allFloors";
  static PUT_FLOOR_SEAT_LAYOUT_IMAGE = "api/admin/session/floor/seat-layout/update";

  //Auditorium Levels
  static POST_LEVEL = "api/admin/session/level/create";
  static PUT_LEVEL = "api/admin/session/level/update";
  // static DELETE_LEVEL = 'api/level/delete';
  static GET_LEVEL = "api/session/level";
  static GET_ALL_LEVEL = "api/allLevelsByFloor";
  static GET_LEVELS = "api/allLevels"
  static GET_ALL_LEVEL_BY_CATEGORY="api/allLevels";  // This api will be updated in Intellij
  static POST_LEVEL_BY_EVENT = "api/admin/session/event/level/create";
  static PUT_LEVEL_BY_EVENT = "api/admin/session/event/level/update";


  //Auditorium Rows
  static POST_ROWS = "api/admin/session/row/create";
  static PUT_ROWS = "api/admin/session/row/update";
  static GET_ROWS = "api/admin/session/row";
  static GET_ALL_ROWS = "api/admin/session/rows";
  static DELETE_ROWS = "api/admin/session/row/delete";

 //Auditorium Seats
 static POST_SEATS = "api/admin/session/seat/create";
 static PUT_SEATS = "api/admin/session/seat/update";
 static PUT_SEATS_EVENT = "api/admin/session/event/seat/update";
 static PUT_SELECTED_SEATS = "api/seat/update";
 static GET_SEATS = "api/seats";
 static GET_SEATS_BY_AUDITORIUM = "api/getSeats";
 static GET_SEATS_ROW = "api/admin/session/seat";

  //Event
  static POST_EVENT = "api/admin/session/event/create";
  static PUT_EVENT = "api/admin/session/event/update";
  static GET_EVENT = "api/event";
  static GET_ALL_EVENT = "api/events";
  static GET_ALL_UPCOMING_EVENT = "api/upcomingEventList";
  static PUT_EVENT_BY_FLOOR = "api/session/event/update";

  //Event Banner Image
  static POST_EVENT_BANNER = "api/admin/session/event-banner/create";
  static DELETE_EVENT_BANNER = "api/admin/session/event-banner/delete";
  static GET_ALL_EVENT_BANNER = "api/event-banner";
  static PUT_EVENT_THUMBNAIL_IMAGE = "api/admin/session/event-thumbnail/update";

  //Home Banner Images
  static GET_ALL_HOME_BANNER = "/api/home-banner";
  static POST_CREATE_HOME_BANNER = "/api/admin/session/home-banner/create";
  static DELETE_HOME_BANNER = "/api/admin/session/home-banner/delete";

  //Auditorium Levels
  static POST_BLOCK = "api/admin/session/block/create";
  static PUT_BLOCK = "api/admin/session/block/update";
  // static DELETE_LEVEL = 'api/block/delete';
  static GET_BLOCK = "api/session/block";
  static GET_ALL_BLOCK = "api/allBlocks";

  //Event Artist Image
  static POST_EVENT_ARTIST = "api/admin/session/event-artist/create";
  static DELETE_EVENT_ARTIST = "api/admin/session/event-artist/delete";
  static GET_ALL_EVENT_ARTIST = "api/event-artist";
  static PUT_EVENT_ARTIST_THUMBNAIL_IMAGE = "api/admin/session/event-artist-thumbnail/update";

  //Payment
  static GET_USER_FOR_PAYMENT = '/api/session/razor-payment/getUserInfo';
  static POST_PAYMENT_DETAILS = '/api/session/hdfc-payment/getPaymentCheckoutInfo';
  static POST_BOOKING_DETAILS = '/api/session/hdfc-payment/create/booking';
  static GET_BOOKING_SESSION = '/api/session/hdfc-payment/clearSession';

  //Booking
  static BOOKING = 'api/session/ticket/booking';
  static GET_BOOKING_DETAILS = 'api/session/booking';
  static MY_BOOKINGS = 'api/session/my-booking';
  static BOOKING_BY_EVENTS ='api/admin/session/bookingList';
  static GET_ALL_USERS_BY_EVENT = 'api/admin/session/event/getAllUsers';
  static GET_ALL_USERS_BY_DATE = 'api/admin/session/bookingList/byDate';
  static GET_ALL_USERS_BY_CANCEL_BOOKING = 'api/admin/session/cancelBookingList/byDate';
  static GET_BOOKING_LIST_BY_FLOOR_AND_EVENT = 'api/admin/session/booking/floorTickets';
  static GET_BOOKING_LIST_BY_USER = 'api/admin/session/bookingList/byUser';
  static GET_BOOKING_LIST_BY_EVENT_AND_USER = 'api/admin/session/bookingList/byEvent/byUser';

  //Discount
  static POST_DISCOUNT = "api/admin/session/discount/create";
  static PUT_DISCOUNT = "api/admin/session/discount/update";
  static GET_DISCOUNT = "api/admin/session/discount";
  static GET_ALL_DISCOUNTS = "api/admin/session/discounts";
  static APPLY_DISCOUNT = "api/session/apply_discount";

  //Notifications
  static POST_IMAGE = 'api/admin/session/email/uploadImage';
  static SEND_EMAIL = '/api/admin/session/send_email';
  static SEND_SMS = '/api/admin/session/send_sms';

  //Settings
  static GET_LOGEDDIN_CUSTOMER = 'api/customer/admin/session/getcustomer';
  static PUT_CUSTOMER_EMAIL_SETTINGS = 'api/customer/admin/session/update/email/settings';

}

export class GlobalConstants {
  static ADMIN_USER = "ROLE_ADMIN";
  static ROLE_SUPER_USER = "ROLE_SUPER_USER";
  static ROLE_CONTENT_UPLOADER = "ROLE_CONTENT_UPLOADER";
  static ROLE_SITE_MANAGER = "ROLE_SITE_MANAGER";
  static ROLE_REPORTS = "ROLE_REPORTS";
}

export const FILE_UPLOAD_PROGRESS = {
  observe: "events",
  reportProgress: true
};
