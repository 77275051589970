
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { AppComponent } from './app.component';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { AuthService } from './shared/auth/auth.service';
import { AuthGuard } from './shared/auth/auth-guard.service';
import { LightboxModule } from 'ngx-lightbox';
import { DatePipe } from '@angular/common';
import { ChartModule } from 'angular-highcharts';

import * as $ from 'jquery';
import { UserRolesService } from './shared/user/user-roles.service';
import { HttpService } from './shared/service/httpService';
import { ServiceStatusService } from 'app/shared/common/service/service-status.service';
import { CustomInterceptor } from 'app/shared/service/customInterceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { UserService } from './shared/service/user.service';
import { SharedModule } from './shared/shared.module';
import { SafehtmlPipe } from './shared/safepipe/safehtml.pipe';

@NgModule({
    declarations: [
        AppComponent,
        SafehtmlPipe
    ],
    imports: [
        LoadingBarHttpClientModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        SharedModule,
        HttpClientModule,
        Ng2SmartTableModule,
        ToastrModule.forRoot(),
        NgbModule.forRoot(),
        LightboxModule,
        ChartModule
    ],
    providers: [
        AuthService,
        AuthGuard,
        UserRolesService,
        HttpService,
        ServiceStatusService,
        UserService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CustomInterceptor,
            multi: true,
        },
        DatePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
