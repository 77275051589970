import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { JwtToken } from '../model/jwtToken';
import { HttpService } from '../service/httpService';
import { UserService } from '../service/user.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/finally';
import { User } from '../model/user';
import { RestAPI } from '../api.constants';


@Injectable()
export class AuthService {
    public token: string;
    public name: string;
    private authSubject = new BehaviorSubject<boolean>(false);
    isAutheticated: Observable<boolean>;


    constructor(private httpService: HttpService,
                private router: Router, 
                private userService: UserService) {
        // set token if saved in local storage
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.token = currentUser && currentUser.token;
        this.isAutheticated = this.authSubject.asObservable();
        this.authSubject.next(this.token ? true : false);
    }
    
   loginByGoogle(requestBody: any): Observable<JwtToken | null> {
        return this.httpService.post<JwtToken>(RestAPI.GOOGLE_LOGIN, requestBody)
          .
            map((tokenObj: JwtToken) => {
              if (tokenObj && tokenObj.success) {
                this.token = tokenObj.token;
                this.name = tokenObj.name;
      
                localStorage.setItem('currentUser', JSON.stringify(tokenObj));
                localStorage.setItem('socialLogin', 'true');
                localStorage.setItem('userName', this.name);
      
                this.userService.reloadUserInfo();
                return tokenObj;
              } else {
                return null;
              }
            })
            .catch((error: any) => {
                            return Observable.of(null);
                        });
          
      }
      

    login(email: string, password: string): Observable<JwtToken> {
        return this.httpService.post<JwtToken>(RestAPI.LOGIN, { email: email, password: password })
            .map((tokenObj: JwtToken) => {
                // login successful if there's a jwt token in the response
                if (tokenObj && tokenObj.success) {

                    // set token property
                    this.token = tokenObj.token;
                    this.name = tokenObj.name;

                    // store username and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(tokenObj));
                    localStorage.setItem('userName', this.name);

                    this.userService.reloadUserInfo();

                    this.authSubject.next(true);

                    // return true to indicate successful login

                    // this.router.navigate(['/admin']);

                    return tokenObj;
                } else {
                    // return false to indicate failed login
                    return tokenObj;
                }
            })
            .catch((error: any) => {
                return Observable.of(null);
            });
    }

    loginByToken(token: string): Observable<JwtToken> {

        return this.userService.getUserByAccessToken(token)
            .map((tokenObj: JwtToken) => {
                // login successful if there's a jwt token in the response

                if (tokenObj && tokenObj.success) {

                    // set token property
                    this.token = tokenObj.token;
                    this.name = tokenObj.name;

                    // store username and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(tokenObj));
                    localStorage.setItem('socialLogin', 'true');
                    localStorage.setItem('userName', this.name);

                    this.userService.reloadUserInfo();

                    this.authSubject.next(true);

                    // return true to indicate successful login
                    return tokenObj;
                } else {
                    // return false to indicate failed login
                    return tokenObj;
                }
            })
            .catch((error: any) => {
                return Observable.of(null);
            });
    }

    logout(): void {
        // clear token remove user from local storage to log user out
        this.token = null;
        localStorage.removeItem('currentUser');
        localStorage.removeItem('socialLogin');
        localStorage.removeItem('userName');

        if (this.isSocialLogin()) {
            this.httpService.delete('/signin/facebook');
        }

        localStorage.removeItem('socialLogin');
        this.authSubject.next(false);
    }

    isUserAuthenticated() {
        if (localStorage.getItem('currentUser')) {
            const currentUser = JSON.parse(localStorage.getItem('currentUser'));

            if (currentUser && currentUser.token) {
                return this.userService.getLoggedInUser()
                    .map((user: User) => {
                        if (user) {
                            return true;
                        } else {
                            return false;
                        }
                    },
                        (error: any) => {
                            if (error.status = 403) {
                                this.logout();
                                return false;
                            } else { // need to check
                                return false;
                            }
                        }
                    ).flatMap(isUser => {
                        this.CheckAuthSubjectChanged(isUser);
                        return Observable.of(isUser);
                    })
            } else {
                this.CheckAuthSubjectChanged(false);
                return Observable.of(false);
            }
        } else {
            this.CheckAuthSubjectChanged(false);
            return Observable.of(false);
        }
    }

    CheckAuthSubjectChanged(newStatus: boolean) {
        if (this.authSubject.getValue() !== newStatus) {
            this.authSubject.next(newStatus);
        }
    }


    isSocialLogin() {
        if (localStorage.getItem('socialLogin')) {
            const issocialLogin = localStorage.getItem('socialLogin');

            if (issocialLogin && issocialLogin === 'true') {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    getUserName() {
        if (localStorage.getItem('userName')) {
            return localStorage.getItem('userName');
        } else {
            return '';
        }
    }

    getUser() {
        if (localStorage.getItem('currentUser')) {
            return JSON.parse(localStorage.getItem('currentUser'));
        } else {
            return null;
        }
    }

    showAddEditByRole() {
        let showAddEdit = false;

        const loggedInUser = this.getUser();
        if (loggedInUser) {
            if (loggedInUser.roles.includes('ROLE_ADMIN')) {
                showAddEdit = true;
            } else {
                showAddEdit = false;
            }
        }
        return showAddEdit;
    }

    isAdmin() {
        let isAd = false;

        const loggedInUser = this.getUser();
        if (loggedInUser) {
            if (loggedInUser.roles.includes('ROLE_ADMIN')) {
                isAd = true;
            } else {
                isAd = false;
            }
        }
        return isAd;
    }
}
