import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpService } from '../service/httpService';
import { UserRole } from './user-role.model';
import { Roles } from '../model/roles';
import { RestAPI } from '../api.constants';

@Injectable()
export class UserRolesService extends HttpService {

  roles: Array<UserRole> = [];

  constructor(http: HttpClient) {

    super(http);


    // FIXME should me be moved to service

    // Sample test data
    const superUser = new UserRole('ROLE_SUPER_USER');
    superUser.addStates(
      '/admin',
      '/admin/home',
      '/admin/profile',
      '/admin/change-password',
      '/admin/home-banner',
      '/admin/add-home-banner',
      '/admin/edit-home-banner',
      '/admin/auditorium',
      '/admin/add-auditorium',
      '/admin/edit-auditorium',
      '/admin/auditorium-floor',
      '/admin/add-floor',
      '/admin/edit-floor',
      '/admin/auditorium-level',
      '/admin/auditorium-levels',
      '/admin/add-level',
      '/admin/edit-level',
      '/admin/event',
      '/admin/add-event',
      '/admin/edit-event',
      '/admin/event-banner',
      '/admin/add-event-banner',
      '/admin/edit-event-banner',
      '/admin/settings',
      '/admin/reports/event-booking-report',
      '/admin/reports/daily-sales-report',
      '/admin/reports/daily-sales-report-by-category',
      '/admin/reports/daily-sales-report-by-transaction',
      '/admin/reports/daily-sales-report-by-transaction-cancelled',
      '/admin/reports/daily-sales-report-by-user',
      '/admin/reports/view-daily-sales-report-user',
      '/admin/reports/signed-up-users',
      '/admin/admin-users',
      '/admin/add-admin-user',
      '/admin/edit-admin-user',
      '/admin/auditorium-rows',
      '/admin/add-auditorium-rows',
      '/admin/edit-auditorium-rows',
      '/admin/event-artists',
      '/admin/add-event-artist',
      '/admin/add-edit-event-artists',
      '/admin/ticket-block',
      '/admin/discount',
      '/admin/add-event-price',
      '/admin/notifications/send-email',
      '/admin/notifications/send-sms',
      '/admin/edit-event-price',
      '/admin/auditorium-levelId',
      '/admin/settings/email'
    );
    const adminUser = new UserRole('ROLE_ADMIN');
    adminUser.addStates(
      '/admin',
      '/admin/home',
      '/admin/profile',
      '/admin/change-password',
      '/admin/home-banner',
      '/admin/add-home-banner',
      '/admin/edit-home-banner',
      '/admin/auditorium',
      '/admin/add-auditorium',
      '/admin/edit-auditorium',
      '/admin/auditorium-floor',
      '/admin/add-floor',
      '/admin/edit-floor',
      '/admin/auditorium-level',
      '/admin/auditorium-levels',
      '/admin/add-level',
      '/admin/edit-level',
      '/admin/event',
      '/admin/add-event',
      '/admin/edit-event',
      '/admin/event-banner',
      '/admin/add-event-banner',
      '/admin/edit-event-banner',
      '/admin/settings',
      '/admin/reports/event-booking-report',
      '/admin/reports/daily-sales-report',
      '/admin/reports/daily-sales-report-by-category',
      '/admin/reports/daily-sales-report-by-transaction',
      '/admin/reports/daily-sales-report-by-transaction-cancelled',
      '/admin/reports/daily-sales-report-by-user',
      '/admin/reports/view-daily-sales-report-user',
      '/admin/reports/signed-up-users',
      '/admin/admin-users',
      '/admin/add-admin-user',
      '/admin/edit-admin-user',
      '/admin/auditorium-rows',
      '/admin/add-auditorium-rows',
      '/admin/edit-auditorium-rows',
      '/admin/event-artists',
      '/admin/add-event-artist',
      '/admin/add-edit-event-artists',
      '/admin/ticket-block',
      '/admin/discount',
      '/admin/add-discount',
      '/admin/edit-discount',
      '/admin/add-event-price',
      '/admin/notifications/send-email',
      '/admin/notifications/send-sms',
      '/admin/settings/email',
      '/admin/auditorium-levelId',
      '/admin/edit-event-price'
    );


    this.roles.push(superUser);
    this.roles.push(adminUser);

  }

  getUserRoles(): Array<UserRole> {
    return this.roles;
  }

  getUserRole(role: string): UserRole {
    for (const userRole of this.roles) {
      if (userRole.role === role) {
        return userRole;
      }
    }
    return null;
  }
}
