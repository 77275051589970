import { Router } from '@angular/router';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../auth/auth.service';
import 'rxjs/add/operator/finally';
import 'rxjs/add/operator/do';
import { ServiceStatusService } from '../common/service/service-status.service';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
declare var $;

@Injectable()
export class CustomInterceptor implements HttpInterceptor {

    count: number = 0;

    constructor(private injector: Injector, private router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const authenticationService: AuthService = this.injector.get(AuthService);
        const serviceStatus: ServiceStatusService = this.injector.get(ServiceStatusService);

        const token: string = authenticationService.token;

        // add it if we have one
        if (token) {
            req = req.clone({ headers: req.headers.set('x-auth-token', token) });
        }

        // if (!req.headers.has('Content-Type')) {
        //     req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
        // }

        req = req.clone({ headers: req.headers.set('Accept', 'application/json') });

        // console.log(JSON.stringify(req.headers));
        // const messageService = this.injector.get(ToastrService);

        return next.handle(req)
            .do((request: HttpEvent<any>) => {
                if (request.type === 0) {
                    this.count++;
                }
                serviceStatus.changeState(true);
            })
            .catch((error: any) => {
                return this.handleError(error);
            })
            .finally(() => {
                this.count--;
                if (this.count === 0) {
                    serviceStatus.changeState(false);
                }
            });
    }

    private handleError(error: any): ErrorObservable<any> {
        //  console.log(error);
        if (error.status === 403) {
            this.router.navigate(['/session/home']);
        } else {
            $.jAlert({ 'title': 'Processing', 'content': 'Please Wait..', 'theme': 'blue', 'closeOnClick': true });
            // messageService.warning('Internal Error !', 'Please try again later.');
        }
        return Observable.throw(error);
    }

}
