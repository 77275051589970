import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './shared/auth/auth-guard.service';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'session',
    pathMatch: 'full'
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadChildren: 'app/pages/full-pages/full-pages.module#FullPagesModule'
  },
  {
    path: 'session',
    loadChildren: 'app/pages/content-pages/content-pages.module#ContentPagesModule'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})

export class AppRoutingModule {

}
